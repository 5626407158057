import React, {useContext, useMemo} from 'react';

import {Route, Switch} from 'react-router-dom';
import lazy from 'lib/asyncComponent';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {FacetIntro} from 'modules/layout';
import {TASK_STATUSES} from 'modules/project';

import Card from '@renofi/components-internal/src/next/Card';
import {Box, Alert} from '@renofi/components-internal';
import {isTpoProcess} from '@renofi/utilities/src/application-process';

import Task from './components/Task';
import {
  sortTasks,
  getProductDetails,
  getProductCertificationDetails,
} from './utils';
import {MessageBox} from './styled';

const Wizard = lazy(
  () => import(/* webpackChunkName: "details-wizard" */ './components/Wizard'),
);

const ProductConfirmation = () => {
  const {allTasks: tasks} = useContext(ProjectTasksContext);

  const sortedTasks = useMemo(() => {
    return sortTasks(tasks);
  }, [JSON.stringify(tasks)]);

  const productDetailsTask = useMemo(() => {
    return getProductDetails(tasks);
  }, [tasks]);

  const certificateAcknowledgementTask = getProductCertificationDetails(tasks);
  const isCertificateAcknowledgementCompleted = Boolean(
    certificateAcknowledgementTask?.completedAt,
  );

  const {productDetailsConfirmation, status} = productDetailsTask || {};

  const isTpoTask = isTpoProcess(
    productDetailsConfirmation?.applicationProcess,
  );
  const isProductDetailsCompleted = status === TASK_STATUSES.completed;
  const isProductDetailsProcessing = status === TASK_STATUSES.processing;

  const isSubmitted = isProductDetailsCompleted || isProductDetailsProcessing;
  const isTpoProductDetailsProcessing = isProductDetailsProcessing && isTpoTask;
  const isNonTpoProductDetailsProcessing =
    isProductDetailsProcessing && !isTpoTask;

  return (
    <>
      <Card p={0}>
        <FacetIntro title="Product details">
          {!isTpoProductDetailsProcessing && !isSubmitted ? (
            <Alert>
              <Box>
                Please take a moment to finalize your loan application details
                by by completing the tasks below.
              </Box>
            </Alert>
          ) : null}
          {isTpoProductDetailsProcessing ? (
            <MessageBox p={0} alignItems="center" variant="warning">
              Your RenoFi Advisor will review your details and contact you to
              discuss the next steps. You are not quite ready to schedule a
              final review call as some of your details are not ready to share
              with the Lender.
            </MessageBox>
          ) : null}
          {isNonTpoProductDetailsProcessing ? (
            <MessageBox p={0} alignItems="center" variant="warning">
              Your RenoFi Advisor will review your details and contact you to
              discuss the next steps.
            </MessageBox>
          ) : null}
        </FacetIntro>
        {sortedTasks.map((task) => (
          <Task
            key={task.id}
            {...task}
            isProductDetailsCompleted={isProductDetailsCompleted}
            isCertificateAcknowledgementCompleted={
              isCertificateAcknowledgementCompleted
            }
          />
        ))}
      </Card>
      <Switch>
        <Route
          path={`/:projectId/tasks/:facet(product_confirmation)/:taskId?/:step?`}>
          <Wizard tasks={tasks} />
        </Route>
      </Switch>
    </>
  );
};

export default ProductConfirmation;
