import React, {useState, useMemo, useEffect} from 'react';

import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {descend, isNil, prop, sortWith, isEmpty} from 'ramda';

import {Box, Button, DateFormat, Flex, Text} from '@renofi/components-internal';
import Clock from '@renofi/icons/src/Clock';
import {getDatePickerLabel} from '@renofi/modules-internal';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import {useQueryString} from '@renofi/utilities/src/hooks';
import {
  useToggledBorrowerProject,
  useGetUser,
  useProjectTimeline,
  useProjectRenovationUpdatesV2,
} from '@renofi/graphql/src/hooks';
import {projectStatus} from '@renofi/utilities/src/enums';

import UpdateModal from '../Update';

import RenovationStartDateModal from './components/RenovationStartDateModal/RenovationStartDateModal';
import EditIcon from './components/EditIcon';
import Dummy from './components/Dummy';
import FormattedDate from './components/FormattedDate';
import {getRecordData, isFresh} from './utils';
import {
  Consent,
  Content,
  Marker,
  Photo,
  PhotoLink,
  Record,
  Title,
  Wrapper,
  StyledContainer,
} from './styled';

const Timeline = ({
  smsConsentVisible,
  handleShowSmsConsent,
  changeRenovationStateId,
}) => {
  const {user} = useGetUser();
  const {action} = useParams();
  const params = useQueryString();

  const [isRenovationUpdateModalVisible, setIsRenovationUpdateModalVisible] =
    useState(action === 'add_update' || Boolean(params.add_update));
  const [
    isRenovationStartDateModalVisible,
    setIsRenovationStartDateModalVisible,
  ] = useState(false);

  const {project} = useToggledBorrowerProject();

  const {timeline: projectTimeline, fetch: fetchTimeline} = useProjectTimeline({
    lazy: true,
  });
  const {renovationUpdates, fetch: fetchRenovationUpdates} =
    useProjectRenovationUpdatesV2({
      lazy: true,
    });

  useEffect(() => {
    const projectId = project?.id;

    if (!projectId) {
      return;
    }

    fetchTimeline({
      variables: {id: projectId},
    });

    fetchRenovationUpdates({
      variables: {id: projectId},
    });
  }, [project]);

  const sortedUpdates = useMemo(() => {
    return sortWith([descend(prop('createdAt'))], renovationUpdates);
  }, [renovationUpdates]);

  const {expectedRenovationStart, renovationStartedOn, renovationCompletedOn} =
    projectTimeline || {};
  const {id: projectId, status} = project || {};

  const isAddUpdateDisabled =
    status !== projectStatus.closedWithLender &&
    status !== projectStatus.renovating;

  const complete = renovationCompletedOn;

  const showAddEstimatdStartDateBtn =
    !expectedRenovationStart && !renovationStartedOn;
  const showAddUpdateBtn =
    !renovationCompletedOn && !showAddEstimatdStartDateBtn;

  const onAddRenovationUpdate = () => {
    setIsRenovationUpdateModalVisible(true);
    changeRenovationStateId(null);
  };

  const onEditRenovationUpdate = (id) => {
    changeRenovationStateId(id);
    setIsRenovationUpdateModalVisible(true);
  };

  const onCloseRenovatioUpdateModal = () => {
    setIsRenovationUpdateModalVisible(false);
  };

  const recordData = getRecordData(projectTimeline || {});

  if (isNil(project)) {
    return null;
  }

  return (
    <>
      <StyledContainer head="Your renovation timeline">
        {!smsConsentVisible && projectId ? (
          <Consent
            onClick={(event) => {
              event.preventDefault();
              handleShowSmsConsent();
            }}>
            SMS reminder is active
          </Consent>
        ) : null}
        <Text mb={24}>
          {isAddUpdateDisabled ? (
            <>
              Here you’ll be able to see your renovation timeline and post
              updates on how things are progressing to keep the Lender (and us)
              in the loop. This will also serve as a diary in case you run into
              any issues so that we can help you.
            </>
          ) : null}
          {complete ? (
            <>
              It was lovely watching your renovation dreams come true, thanks
              for bringing us on the journey!
            </>
          ) : null}
          {!(isAddUpdateDisabled || complete) ? (
            <>
              It’s required to keep the Lender posted on the renovation progress
              by adding renovation updates. The RenoFi team will be reaching out
              periodically to remind you in case you forget.
            </>
          ) : null}
        </Text>
        <Wrapper>
          {showAddEstimatdStartDateBtn ? (
            <Box mb={4}>
              <Button
                onClick={() => setIsRenovationStartDateModalVisible(true)}>
                Add Estimated renovation start date
              </Button>
            </Box>
          ) : null}
          {showAddUpdateBtn ? (
            <Record pl={0}>
              <Box css={{position: 'relative', left: -50}}>
                <Button
                  disabled={isAddUpdateDisabled}
                  onClick={onAddRenovationUpdate}>
                  Add update
                </Button>
              </Box>
            </Record>
          ) : null}
          {isAddUpdateDisabled ? (
            <Dummy />
          ) : (
            sortedUpdates.map((update) => {
              const {
                id,
                createdAt,
                date,
                details,
                photos,
                kind,
                user: owner,
              } = update || {};
              const dateLabel = getDatePickerLabel(kind);

              const showEditIcon = isFresh(createdAt) && owner?.id === user?.id;

              return (
                <Flex key={id} width={1} justifyContent="space-between">
                  <Record>
                    <Marker />
                    <Title>
                      <FormattedDate value={createdAt} />
                      <div>-</div>
                      <Clock width={16} /> {humanizeSnakeCase(kind)}
                    </Title>
                    <Content>{details}</Content>
                    {date ? (
                      <Content>
                        {dateLabel}: <DateFormat value={date} time={false} />
                      </Content>
                    ) : null}
                    <Flex mt="8px">
                      {!isEmpty(photos)
                        ? photos.map(({original}) => (
                            <PhotoLink
                              key={original.objectName}
                              blank
                              href={original?.url}>
                              <Photo src={original.url} />
                            </PhotoLink>
                          ))
                        : null}
                    </Flex>
                  </Record>
                  {showEditIcon ? (
                    <EditIcon
                      createdAt={createdAt}
                      onClick={() => onEditRenovationUpdate(id)}
                    />
                  ) : null}
                </Flex>
              );
            })
          )}
          {recordData ? (
            <Record final>
              <Marker />
              <Title>
                <FormattedDate value={recordData.value} />
              </Title>
              <Content>{recordData.label}</Content>
            </Record>
          ) : null}
        </Wrapper>
      </StyledContainer>
      {!isAddUpdateDisabled && isRenovationUpdateModalVisible ? (
        <UpdateModal onClose={onCloseRenovatioUpdateModal} />
      ) : null}
      {isRenovationStartDateModalVisible ? (
        <RenovationStartDateModal
          projectId={projectId}
          onClose={() => setIsRenovationStartDateModalVisible(false)}
        />
      ) : null}
    </>
  );
};

Timeline.propTypes = {
  smsConsentVisible: PropTypes.bool.isRequired,
  handleShowSmsConsent: PropTypes.func,
  changeRenovationStateId: PropTypes.func,
};

export default Timeline;
