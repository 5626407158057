import {useEffect} from 'react';

import FlagProvider from '@unleash/proxy-client-react';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {isEmpty} from 'ramda';
import ImpersonationHeader from 'modules/layout/components/Header';

import {useGetUser} from '@renofi/graphql';
import {FeatureFlags} from '@renofi/modules-internal';
import {useReturnsAfterOneMonthAnalytics} from '@renofi/utilities';
import {pageView} from '@renofi/utilities/src/analytics';
import {UUID_NAME_REGEX} from '@renofi/utilities/src/react/validateUuidProp';

import ProjectRouter from './components/ProjectRouter';
import ProjectRedirect from './components/ProjectRedirect';

const UUID_STRING = UUID_NAME_REGEX.toString().replace(/^\/|\/i$/gi, '');

const unleashConfig = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: `secure-app-${process.env.REACT_APP_ENV}`,
  environment: process.env.REACT_APP_ENV,
};

const Root = () => {
  const {loading, user} = useGetUser();
  const history = useHistory();
  const location = useLocation();
  useReturnsAfterOneMonthAnalytics(user);

  useEffect(() => {
    const {pathname} = location;
    pageView(pathname, {path: pathname});
    history.listen(async ({pathname: path}) => {
      pageView(path, {path});
    });
  }, []);

  /**
   * @TODO - Confirm no JWT / non-authed start-up for all edge-cases re. fetch();
   *
   */

  if (loading || isEmpty(user)) {
    return null;
  }

  return (
    <>
      <FlagProvider config={unleashConfig}>
        <ImpersonationHeader />
        <Switch>
          <Route path={`/:projectId(${UUID_STRING})/:step?`}>
            <ProjectRouter />
          </Route>
          <Route exact path="/feature-flags" component={FeatureFlags} />
          <Route path="/">
            <ProjectRedirect />
          </Route>
        </Switch>
      </FlagProvider>
    </>
  );
};
export default Root;
