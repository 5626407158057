import React, {useCallback} from 'react';

import PropTypes from 'prop-types';
import {useAddEditButtonVariant, useCurrentTask} from 'modules/project';
import {TasksWizardContext} from 'modules/tasks-wizard';

import {
  useMarkTaskFinishedByBorrower,
  useMarkTaskAsOutstanding,
  useMarkTaskDataAsReadyForProcessing,
} from '@renofi/graphql/src';
import {useNotifications} from '@renofi/utilities/src/hooks';

import {Button} from './styled';

const canMarkAsReady = (taskType) =>
  ['additional_documents', 'appraisal_recent_transactions'].includes(taskType);

const AddEditTaskButton = ({taskId, facet, wizard: Wizard, wizardProps}) => {
  const {
    isCompleted,
    task = {},
    refetchProjectTasks,
    sendEvent,
  } = useCurrentTask({taskId, facet});

  const {addNotification} = useNotifications();
  const {markTaskAsOutstanding} = useMarkTaskAsOutstanding();
  const {markTaskFinishedByBorrower} = useMarkTaskFinishedByBorrower();
  const {markTaskDataAsReadyForProcessing} =
    useMarkTaskDataAsReadyForProcessing({});

  const {details, taskType, title} = task;

  const [label, variant] = useAddEditButtonVariant({taskId, facet});
  const showTaskButton = label && !isCompleted;

  const onCloseModal = async ({setLoading}) => {
    setLoading(true);
    await markTaskAsOutstanding({variables: {taskId}});
    refetchProjectTasks();
    setLoading(false);
  };

  const onFinishedTask = useCallback(
    async ({onClose, setLoading}) => {
      setLoading(true);
      const params = {variables: {taskId}};
      const markReady = canMarkAsReady(taskType);
      const requests = [markTaskFinishedByBorrower(params)];
      if (markReady) {
        requests.push(markTaskDataAsReadyForProcessing(params));
      }

      await Promise.all(requests);
      refetchProjectTasks();
      sendEvent('Secure/Task-Finished');
      addNotification({
        variant: 'success',
        content: '1 task completed and sent to RenoFi for review.',
        type: 'snackbar',
      });
      setLoading(false);
      onClose();
    },
    [taskId, taskType],
  );

  if (!showTaskButton) {
    return null;
  }

  return (
    <TasksWizardContext.Consumer>
      {({onClose, openModal, setLoading}) => {
        const modalProps = {
          acceptLabel: 'I’m done with this task',
          component: Wizard,
          hasButtons: true,
          header: title,
          onAccept: () => onFinishedTask({onClose, setLoading}),
          onClose: () => onCloseModal({setLoading}),
          details,
          facet,
          taskId,
          taskType,
          ...wizardProps,
        };

        return (
          <Button onClick={() => openModal(modalProps)} variant={variant}>
            {label}
          </Button>
        );
      }}
    </TasksWizardContext.Consumer>
  );
};

AddEditTaskButton.propTypes = {
  taskId: PropTypes.string.isRequired,
  facet: PropTypes.string,
  wizard: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType])
    .isRequired,
  wizardProps: PropTypes.object,
};

AddEditTaskButton.defaultProps = {
  wizardProps: {},
};

export default AddEditTaskButton;
