import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';

import Flex from '@renofi/components-internal/src/Flex';

const getSlideInByPosition = (position = 'left') => {
  const startPosition = position === 'left' ? '-100%' : '100%';
  return keyframes`
    0% {
      transform: translateX(${startPosition});
    }
    100% {
      transform: translateX(0);
    }
  `;
};

export const Wrapper = styled(Flex)(
  {
    background: 'white',
    overflow: 'hidden',
  },
  ({
    animate = false,
    duration = 500,
    fixed = true,
    position = 'left',
    sticky,
    width = 300,
    left = 0,
    top = 0,
    show,
  }) => ({
    animation: animate
      ? `${getSlideInByPosition(position)} ${duration}ms`
      : 'none',
    transition: `all ${duration}ms ease`,
    width,
    height: top > 0 ? `calc(100% - ${top}px)` : '100%',
    ...(fixed ? {position: 'fixed'} : {}),
    ...(sticky ? {position: 'sticky'} : {}),
    ...(fixed || sticky
      ? {
          height: top > 0 ? `calc(100vh - ${top}px)` : '100vh',
          [position === 'right' ? 'marginLeft' : 'marginRight']: 8,
          [position]: show ? left : -1 * width - 8,
          top,
          zIndex: 1000,
          boxShadow: `${position === 'right' ? '-4px' : '4px'} 0 8px 0 rgba(0,0,0,0.1)`,
          minWidth: show ? width : 0,
        }
      : {
          width: show ? width : 0,
          height: top > 0 ? `calc(100% - ${top}px)` : '100%',
        }),
  }),
);

export const Content = styled(Flex)(
  {
    flexDirection: 'column',
    flexShrink: 0,
    flexGrow: 1,
  },
  ({width, fixed, sticky}) => ({
    width,
    overflow: fixed || sticky ? 'auto' : 'hidden',
  }),
);
