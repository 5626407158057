import styled from '@emotion/styled';

import {Box, Flex, Link, Text} from '@renofi/components-internal';
import {
  actionDark,
  actionExtraLight,
  basic03,
  basic10,
  basic55,
  dangerDark,
  dangerExtraLight,
  dangerRegular,
  maxSmall,
  successDark,
  successExtraLight,
} from '@renofi/theme';

const getBackgroundColor = ({error, isAccepted, hover}) => {
  switch (true) {
    case hover:
      return actionExtraLight;
    case error:
      return dangerExtraLight;
    case isAccepted:
      return successExtraLight;
    default:
      return basic03;
  }
};

const getBorderColour = ({error, isAccepted, hover}) => {
  switch (true) {
    case hover:
      return actionDark;
    case error:
      return dangerDark;
    case isAccepted:
      return successDark;
    default:
      return basic10;
  }
};

export const Wrapper = styled(Flex)(
  {
    width: '100%',
    height: 56,
    gap: 16,
    borderRadius: 8,
    alignItems: 'center',
    padding: '0 16px',
    cursor: 'pointer',
    [maxSmall]: {
      gap: 8,
      padding: '0 8px',
    },
  },
  (params) => {
    return {
      backgroundColor: getBackgroundColor(params),
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: getBorderColour(params),
    };
  },
);

export const SizeLabel = styled(Text)({
  color: basic55,
  fontSize: 12,
  lineHeight: '20px',
  opacity: 0.6,
});

export const HoverLink = styled(Link)({
  display: 'block',
  marginBottom: -4,
  marginLeft: 8,
  'svg path': {
    pointerEvents: 'disabled',
  },
  ':hover': {
    'svg:not(a>div>svg) path': {
      fill: dangerRegular,
    },
  },
});

export const Circle = styled(Flex)(
  {
    width: 40,
    height: 40,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  ({error, disableCancel}) =>
    disableCancel
      ? {}
      : {
          ':hover': {
            backgroundColor: error
              ? 'rgba(255,82,83,0.2)'
              : 'rgba(128,207,255,0.2)',
          },
        },
);

export const ShowDetails = styled(Box)({
  marginRight: 8,

  [maxSmall]: {
    marginRight: 0,

    button: {
      // marginLeft: 8,
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: 11,
      lineHeight: 'normal',
    },
  },
});
