import {documentWithAllProps} from '../document';

export default `
businessRole
city
coborrowerEmploymentHistory
partTimeIncome
currentEmployment
documents {
  ${documentWithAllProps}
}
employedByParty
employerName
endDate
entityStructures
grossMonthlyIncomeBase
grossMonthlyIncomeBonus
grossMonthlyIncomeCommission
grossMonthlyIncomeMilitaryEntitlements
grossMonthlyIncomeOther
grossMonthlyIncomeOvertime
id
ownershipShareAmount
phoneNumber
selfEmployed
startDate
state
streetAddress
timeInRoleAsMonths
zipCode
`;
