/* eslint-disable max-lines */
import light from './light';
import {
  actionDark,
  actionExtraLight,
  actionLight,
  actionRegular,
  basic20,
  basic55,
  basic75,
  basic80,
  dangerLight,
  dangerRegular,
  successLight,
  successRegular,
  warningRegular,
  white,
} from './colors';

export const alert = {
  default: {
    minHeight: 50,
    height: 'auto !important',
    color: actionLight,
  },
  info: {
    color: actionLight,
  },
  danger: {
    color: dangerLight,
  },
  success: {
    color: successLight,
  },
  warning: {
    color: warningRegular,
  },
};

export const button = light.button;

export const badge = {
  common: {
    backgroundColor: basic80,
  },
  default: {
    color: white,
  },
  danger: {
    color: dangerLight,
  },
  dangerInverse: {
    color: basic80,
    backgroundColor: dangerLight,
  },
  info: {
    color: actionLight,
  },
  infoInverse: {
    color: actionDark,
    backgroundColor: actionLight,
  },
  warning: {
    color: warningRegular,
  },
  success: {
    color: successLight,
  },
  successInverse: {
    color: basic80,
    backgroundColor: successLight,
  },
};

export const buttonGroup = ({active}) => ({
  common: {
    fontSize: 16,
    lineHeight: '24px',
    cursor: 'pointer',
    marginLeft: 1,
    marginRight: 1,
    ':first-of-type': {
      marginLeft: 0,
      borderRadius: '4px 0 0 4px',
    },
    ':last-of-type': {
      marginRight: 0,
      borderRadius: '0 4px 4px 0',
    },
  },
  disabled: {
    cursor: 'default',
    backgroundColor: active ? basic55 : basic75,
  },
  default: {
    backgroundColor: active ? actionRegular : basic75,
    color: white,
    ':hover': {
      backgroundColor: active ? actionRegular : basic55,
    },
  },
});

export const dropZone = ({active, hover}) => {
  const isActive = active || hover;
  return {
    default: {
      backgroundColor: isActive ? basic75 : basic80,
      borderColor: isActive ? actionExtraLight : basic55,
      borderStyle: isActive ? 'solid' : 'dashed',
      borderWidth: 2,
      color: white,

      ':hover': {
        border: `1.5px solid ${actionRegular}`,
        backgroundColor: basic75,
      },
    },
  };
};

export const label = {
  ...light.label,
  default: {
    color: basic55,
  },
};

export const textarea = {
  common: {
    border: 'none',
    borderRadius: 4,
    '> textarea': {
      color: white,
      '&::placeholder': {
        color: `${basic55}`,
      },
    },
  },
  default: {
    backgroundColor: basic75,
  },
};

export const checkbox = ({disabled}) => ({
  default: {
    color: disabled ? '#f0f' : white,
  },
});

export const textField = ({large, left, right}) => ({
  common: {
    borderRadius: 4,
    color: white,
    '> div': {
      padding: large ? '16px' : '12px',
      fontSize: 16,
      color: white,
      '> input': {
        color: white,
        fontSize: 16,
      },
      '> input::placeholder': {
        color: `${basic55}`,
        fontSize: 16,
      },
    },
    '> span': {
      ...(left
        ? {
            borderRight: `1px solid ${basic80}`,
            borderRadius: '4px 0 0 4px',
          }
        : {}),
      ...(right
        ? {
            borderLeft: `1px solid ${basic80}`,
            borderRadius: '0 4px 4px 0',
          }
        : {}),
      borderColor: basic80,
    },
  },
  default: {
    backgroundColor: basic75,
  },
  disabled: {
    '> div': {
      padding: large ? '16px' : '12px',
      fontSize: 16,
      color: white,
      '> input': {
        color: basic55,
        fontSize: 16,
      },
    },
    backgroundColor: basic75,
  },
});

export const selectField = ({flat}) => ({
  common: {
    outline: 'none',
  },
  default: {
    backgroundColor: flat ? 'transparent' : basic75,
    borderRadius: 4,
    color: white,
    '> div': {
      color: white,
    },
    cursor: 'pointer',
  },
  disabled: {
    backgroundColor: basic75,
    color: basic55,
  },
});

export const options = {
  common: {
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.2)',
  },
  default: {
    color: white,
    backgroundColor: basic75,
  },
};

export const option = ({active}) => ({
  default: {
    color: white,
    backgroundColor: active ? actionRegular : basic75,
    ':hover': {
      backgroundColor: actionRegular,
    },
  },
});

export const dateField = {
  common: {
    border: `1px solid ${basic75}`,
    backgroundColor: basic80,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.4)',
    '& .DayPicker-Day': {
      color: white,
    },
    '& .DayPicker-Day--selected': {
      color: white,
      backgroundColor: actionRegular,
    },
    '& .DayPicker-Day--today': {
      color: dangerRegular,
    },
    '& .DayPicker-Day:hover': {
      color: white,
      backgroundColor: `${actionRegular} !important`,
    },
  },
};

export const radioButton = ({checked, disabled, small, height, help}) => {
  const hasHelpLabel = Boolean(help);

  return {
    common: {
      minWidth: 100,
      borderRadius: 4,
      fontSize: 15,
      lineHeight: '18px',
      color: white,

      backgroundColor: disabled ? 'none' : checked ? actionRegular : basic75,
      padding: checked ? 15 : 16,
      height: hasHelpLabel ? 'auto' : small ? 36 : height || 50,
      alignItems: hasHelpLabel ? 'baseline' : 'center',
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
  };
};

export const tab = ({activeColor, active}) => ({
  common: {
    height: active ? 50 : 40,
    background: active ? activeColor || basic80 : basic75,
    borderTop: active ? `solid 4px ${actionRegular}` : 'none',
    color: white,
  },
});

export const toggle = {
  common: {
    background: successRegular,
    border: 'none',
    width: 40,
    height: 20,
    cursor: 'pointer',
    borderRadius: 10,
    '> div': {
      background: basic80,
      border: 'none',
      width: 12,
      height: 12,
      marginLeft: '4px',
    },
    ':focus': {
      boxShadow: 'none',
    },
    '&[aria-checked=false]': {
      background: dangerRegular,
    },
    '&[disabled]': {
      background: basic20,
      cursor: 'default',
    },
  },
};

export default {
  alert,
  badge,
  button,
  buttonGroup,
  checkbox,
  dateField,
  dropZone,
  label,
  option,
  options,
  radioButton,
  selectField,
  tab,
  textarea,
  textField,
  toggle,
};
