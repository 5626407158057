import React, {useContext} from 'react';

import PropTypes from 'prop-types';
import {range} from 'ramda';
import {GOOGLE_REVIEWS_URL} from 'config';

import {Button} from '@renofi/components-internal';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import noop from '@renofi/utilities/src/noop';
import {openTab} from '@renofi/utilities/src/window';

import {Content, Details, Star, StyledFlex, StyledContainer} from './styled';

const LeaveReview = ({onClick, wide}) => {
  const storage = useContext(StorageContext);
  const stars = range(0, 5);

  const onCompleteReview = () => {
    onClick();
    storage.setItem('review:completed', new Date().toISOString());
    openTab(GOOGLE_REVIEWS_URL);
  };

  return (
    <Content
      pt={24}
      pb={60}
      wide={wide}
      alignItems="center"
      head="How was your RenoFi experience">
      <StyledContainer width={384} flexDirection="column" alignItems="center">
        <StyledFlex width={276} justifyContent="space-between" mt={64} mb={48}>
          {stars.map((num) => (
            <Star key={num} />
          ))}
        </StyledFlex>
        <Button large variant="danger" onClick={onCompleteReview}>
          Leave a review
        </Button>
        <Details mt={48}>
          Help other homeowners like you learn about the magic of RenoFi Loans
          by leaving a Google review. Both they and us would appreciate it!
        </Details>
      </StyledContainer>
    </Content>
  );
};

LeaveReview.propTypes = {
  onClick: PropTypes.func,
  wide: PropTypes.bool,
};

LeaveReview.defaultProps = {
  onClick: noop,
};

export default LeaveReview;
