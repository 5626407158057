import React from 'react';

import PropTypes from 'prop-types';

import {Content, Wrapper} from './styled';

const Drawer = ({
  animate = false,
  contentCss = {},
  min,
  max,
  open,
  position = 'left',
  fixed,
  sticky,
  children,
  ...props
}) => (
  <Wrapper
    animate={animate}
    fixed={fixed}
    position={position}
    sticky={sticky}
    width={open ? max : min}
    {...props}>
    <Content fixed={fixed} sticky={sticky} css={contentCss} width={max}>
      {children}
    </Content>
  </Wrapper>
);

Drawer.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.node,
  contentCss: PropTypes.object,
  open: PropTypes.bool,
  fixed: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
  sticky: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default Drawer;
