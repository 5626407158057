import React, {Fragment, useEffect, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {find, isNil, prop, propEq, propOr, sortBy} from 'ramda';
import getDropZoneFileNamePrefix from 'lib/getDropZoneFileNamePrefix';
import {
  useCurrentTask,
  useDocumentHandlers,
  useTaskHandlers,
} from 'modules/project';

import {AlertWithIcon, DocumentViewer} from '@renofi/components-internal';
import {TaskDocument} from '@renofi/modules-internal';
import {useNotifications} from '@renofi/utilities';
import {sendEvent} from '@renofi/utilities/src/analytics';
import noop from '@renofi/utilities/src/noop';
import {useDocumentPdfObjectNameUpdated} from '@renofi/utilities/src/pusher';

import {hasPasswordProtectedDocs} from '../../utils';
import ConfirmRemoveFileModal from '../ConfirmRemoveFileModal';
import FileDropZone from '../FileDropZone';

import {Container} from './styled';

const ManageDocuments = ({
  basePath = 'documents',
  immutable,
  minimize,
  onChange = noop,
  onUploadComplete = noop,
  taskId,
  facet,
  forceRemoveButton,
  customDocuments,
  customResponsePath,
  customMutation,
  customMutationParams,
}) => {
  const [currentDocumentId, setCurrentDocumentId] = useState('');
  const {
    projectId,
    task,
    tasks,
    loading: loadingProjectTasks,
    refetchProjectTasks,
  } = useCurrentTask({taskId, facet});
  const {
    docsUploading,
    onAcceptFiles: uploadFiles,
    onRejectFiles,
    removeUpload,
    submitDocuments,
  } = useDocumentHandlers({
    basePath,
    tasks,
    projectId,
    facet,
  });
  const {onRemoveDocument, loading} = useTaskHandlers({
    tasks,
    projectId,
    facet,
  });
  const {addNotification} = useNotifications();

  const taskType = propOr(null, 'taskType', task);
  const promptNoun = getDropZoneFileNamePrefix(taskType);
  const taskDocuments = propOr([], 'documents', task);

  const documents = useMemo(() => {
    const base = !isNil(customDocuments) ? customDocuments : taskDocuments;
    return sortBy(prop('createdAt'), [...base, ...docsUploading]);
  }, [customDocuments, docsUploading, taskDocuments]);

  const [confirmRemove, setConfirmRemove] = useState(false);
  const [hasDocuments, setHasDocuments] = useState(Boolean(documents?.length));

  const isDropzoneDisabled = Boolean(docsUploading.length) || loading;
  const hasEncrypted = hasPasswordProtectedDocs(documents);

  useEffect(() => {
    setHasDocuments(Boolean(documents?.length));
  }, [documents.length]);

  const onAcceptFiles = async (files) => {
    setHasDocuments(true);
    const finalFiles = await uploadFiles({
      files,
      taskId,
      customMutation,
      customMutationParams,
      customResponsePath,
    });

    await refetchProjectTasks();
    onUploadComplete(finalFiles);
    onChange();
  };

  const onConfirmRemove = async () => {
    const documentId = confirmRemove;
    const doc = find(propEq('id', confirmRemove), documents);
    if (!doc) {
      return false;
    }

    const isSubmitted = doc['__typename'] === 'Document';
    if (isSubmitted) {
      await onRemoveDocument({documentId, taskId});
      await refetchProjectTasks();
      sendEvent('Secure/Task-Remove-Document', {documentId, taskId});
    } else {
      removeUpload(documentId);
    }

    onChange();
    setConfirmRemove(false);
  };

  const onFileDecrypted = async ({filePassword, id}) => {
    const encryptedDoc = find(propEq('id', id), documents);
    if (!encryptedDoc) {
      return false;
    }

    const submitted = await submitDocuments({
      customMutation,
      customMutationParams,
      customResponsePath,
      taskId,
      uploadedFiles: [{...encryptedDoc, filePassword}],
    });

    sendEvent('DocumentUpload/UnlockPasswordProtected', {
      documentId: id,
      objectName: encryptedDoc.objectName,
      taskId,
    });

    await refetchProjectTasks();
    addNotification({
      content: 'Document successfully unlocked',
      variant: 'success',
      type: 'snackbar',
    });
    onUploadComplete(submitted);
    onChange();
  };

  const onRemove = (documentId) => {
    setConfirmRemove(documentId);
  };

  useDocumentPdfObjectNameUpdated({
    projectId,
    onUpdate: () => refetchProjectTasks(),
  });

  return (
    <Container>
      {hasEncrypted ? (
        <AlertWithIcon mb={3} variant="danger">
          One or more password protected files were detected. Please enter a
          password for the corresponding file below.
        </AlertWithIcon>
      ) : null}

      {!immutable && (
        <FileDropZone
          disabled={isDropzoneDisabled}
          hasDocuments={hasDocuments}
          minimize={minimize}
          onAcceptFiles={onAcceptFiles}
          onRejectFiles={onRejectFiles}
          promptNoun={promptNoun}
        />
      )}

      {hasDocuments ? (
        <Fragment>
          {documents.map(({id: documentId, ...doc}) => {
            const isUploading = Boolean(doc.controller);

            return (
              <Fragment key={documentId}>
                <TaskDocument
                  forceRemoveButton={forceRemoveButton}
                  immutable={immutable || isUploading || loadingProjectTasks}
                  mb={2}
                  id={documentId}
                  {...doc}
                  status={isUploading ? 'progress' : doc.status}
                  onDecrypt={onFileDecrypted}
                  onRemove={() => onRemove(documentId)}
                  onClick={() => setCurrentDocumentId(documentId)}
                />
              </Fragment>
            );
          })}

          <ConfirmRemoveFileModal
            loading={loading}
            show={Boolean(confirmRemove)}
            onAccept={() => onConfirmRemove()}
            onClose={() => setConfirmRemove(false)}
            onReject={() => setConfirmRemove(false)}
          />
        </Fragment>
      ) : null}

      {currentDocumentId ? (
        <DocumentViewer
          hasControls
          selectable
          itemId={currentDocumentId}
          visible={Boolean(currentDocumentId)}
          documents={documents}
          onPrev={setCurrentDocumentId}
          onNext={setCurrentDocumentId}
          onClose={() => setCurrentDocumentId(null)}
          onClick={(event) => event.stopPropagation()}
          showCloseButton
          showBackButton={false}
          isNestedModal
        />
      ) : null}
    </Container>
  );
};

ManageDocuments.propTypes = {
  basePath: PropTypes.string,
  facet: PropTypes.string,
  immutable: PropTypes.bool,
  minimize: PropTypes.bool,
  onUploadComplete: PropTypes.func,
  onChange: PropTypes.func,
  forceRemoveButton: PropTypes.bool,
  customMutation: PropTypes.func,
  customMutationParams: PropTypes.object,
  customDocuments: PropTypes.array,
  customResponsePath: PropTypes.string,
  taskId: PropTypes.string,
};

export default ManageDocuments;
