import React from 'react';

import PropTypes from 'prop-types';

import {Text, Flex, Button} from '@renofi/components-internal';
import {Check} from '@renofi/icons';
import {basic80, successRegular, basic55, basic88} from '@renofi/theme';
import formatMoney from '@renofi/utilities/src/formatMoney';

import ContactInformation from '../ContactInformation';

import {
  Container,
  Item,
  List,
  DetailBox,
  DetailBoxSection,
  LogoBox,
  LogoWrapper,
  ItemWrapper,
  IconWrapper,
  WrapperBox,
  ButtonWrapper,
  ButtonText,
} from './styled';

const NonTpoInformation = ({
  lender,
  appraisedValue,
  isDisabled,
  onApplyOnline,
  applyUrl,
}) => {
  const {
    name: lenderName,
    contactInformation1,
    contactInformation2,
    rrfTransferInstructions,
  } = lender || {};

  const hasCustomInformation = rrfTransferInstructions?.length > 0;

  const formattedAppraisal = formatMoney(appraisedValue, {
    fractional: false,
    defaultValue: '',
  });
  const formattedAppraisedValue = formattedAppraisal
    ? `(${formattedAppraisal})`
    : '';

  const listValues = hasCustomInformation
    ? rrfTransferInstructions
    : [
        `${lenderName} will complete a full credit underwrite and may need additional info in order to complete the underwriting process.`,
        `Going forward ${lenderName} will be your primary point of contact for the financing and RenoFi will be checking in periodically for status updates on your renovation.`,
        `If you prefer to speak with someone at ${lenderName} rather than complete the application online, please use the contacts listed on this page.`,
        `When you navigate to the online application there may be multiple products to choose from. Be sure to choose the Home Equity Line of Credit (HELOC) option.`,
        `In the application it will ask you to enter the value of your home. Be sure to use the after renovation value from the appraisal ${formattedAppraisedValue}`,
      ];

  return (
    <Container width={1}>
      <WrapperBox
        width={3 / 5}
        justifyContent="space-between"
        alignItems="center"
        pr={15}>
        <Text
          fontSize={24}
          mb={32}
          lineHeight="28px"
          fontWeight="bold"
          color={basic80}>
          Useful information when applying with {lenderName}
        </Text>
        <List>
          {listValues?.map((item) => (
            <ItemWrapper>
              <IconWrapper>
                <Check color={successRegular} />
              </IconWrapper>
              <Item>{item}</Item>
            </ItemWrapper>
          ))}
        </List>
        {applyUrl && (
          <>
            <ButtonText color={basic88} pl={35}>
              Click the button below to begin your {lenderName} Home Equity Loan
              application.
            </ButtonText>

            <ButtonWrapper
              width={1}
              justifyContent="flex-start"
              mt={isDisabled ? 148 : 24}
              pb={16}>
              <Button
                large
                variant="danger"
                disabled={isDisabled}
                onClick={onApplyOnline}>
                Apply online now
              </Button>
            </ButtonWrapper>
          </>
        )}
      </WrapperBox>

      <DetailBoxSection width={2 / 5}>
        <DetailBox>
          <LogoWrapper>
            <LogoBox lender={lender} />
          </LogoWrapper>
          <Flex pl={20} pr={20} flexDirection="column" mt={16}>
            <Text fontSize={18} mb="8px" color={basic80}>
              Your contacts at {lenderName}
            </Text>
            <Text fontSize={16} color={basic55}>
              If you run into any issues with the online application please
              reach out to the following {lenderName} staff:
            </Text>
          </Flex>
          {(contactInformation1 || contactInformation2) && (
            <ContactInformation lender={lender} />
          )}
        </DetailBox>
      </DetailBoxSection>
    </Container>
  );
};

NonTpoInformation.propTypes = {
  lender: PropTypes.shape({
    contactInformation1: PropTypes.string,
    contactInformation2: PropTypes.string,
    id: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
  }),
  isDisabled: PropTypes.bool,
  onApplyOnline: PropTypes.func,
  applyUrl: PropTypes.string,
  appraisedValue: PropTypes.number,
};

export default NonTpoInformation;
